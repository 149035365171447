<template>
  <div class="form-container px-5 pb-15">
    <div class="d-flex justify-center align-center py-5">
      <h1 class="text-heading--prm ml-2 --w-bold">
        {{ $t("menu.main.items.pointExchangeUsageHistory") }}
      </h1>
    </div>

    <GiftTicketUsageHistoryItem
      v-for="item in giftTicketList"
      :key="item.id"
      :name="item.pointExchangeProduct.name"
      :date="item.createdAt"
      :quantity="item.quantity"
      :status="item.confirmedAt !== null"
      :orderByName="item.name"
    />
  </div>
</template>

<script>
import GiftTicketUsageHistoryItem from '../../../components/GiftTicketUsageHistoryItem'

export default {
  name: 'GiftTicketUsageHistory',
  components: {
    GiftTicketUsageHistoryItem
  },
  created () {
    if (!this.$isAllowed().pointExchangeHistory()) {
      this.$router.replace({ name: 'home' })
    }
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        await this.$store.dispatch('myPointExchangeList', {
          apollo: this.$apollo
        })
      })
    })
  },
  computed: {
    giftTicketList () {
      return this.$store.state.pointExchange.history
    }
  }
}
</script>
