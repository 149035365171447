<template>
  <div
    class="mb-3 text-medium border-medium"
    :class="{ '--border-orange': status, '--border-border': !status }"
  >
    <div class="d-flex justify-space-between align-center"
        :class="{ 'bg-orange --white ': status, ' bg-silver --dark': !status }">
      <div
        class="px-3 py-2"
      >
        {{
          status
            ? $t("giftTicket.ticketIssuerConfirmed")
            : $t("giftTicket.alreadyApplied")
        }}
      </div>
      <div v-if="orderByName && !$isCompanyUser()" style="max-width: 50%" class="px-2">
        申込者: {{ orderByName }}
      </div>
    </div>
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex justify-space-between py-4 px-2 bg-white">
        <div class="d-flex">
          <div class="order-date"> {{ date | jaShortDate }} </div>
          <v-divider class="mx-3 " vertical />
        </div>
        <div class="flex-grow-1 text-center">
          {{ name }}
          <p v-if="status" class="text-normal--orange --w-medium">
            {{ $t('giftTicket.itWillBeSent') }}
          </p>
        </div>
      </div>
      <div
        class="bg-smoke w-80 --c-prm font-weight-bold align-self-stretch d-flex align-center justify-center"
      >
        {{
          formatCurrency(quantity) +
          (Math.abs(quantity) > 1 ? $t("unit.tickets") : $t("unit.ticket"))
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/utils/format'

export default {
  name: 'GiftTicketUsageHistoryItem',
  props: {
    status: {
      type: Boolean,
      default: false
    },
    date: [String, Date],
    orderByName: {
      type: String,
      required: false
    },
    name: String,
    quantity: {
      type: Number,
      default: 0
    }
  },
  methods: {
    formatCurrency
  }
}
</script>

<style lang="scss" scoped>
@use '../../assets/styles/modules/variables-scss' as v;
::v-deep {
  .v-divider {
    border-color: var(--color__primary) !important;
  }
  * {
    color: var(--color__dark-gray);
  }
  .d-flex > .bg-white {
    width: calc(100% - 80px)
  }
  .w-80 {
    width: 80px;
    flex-shrink: 0;
    padding: 16px;
  }
}
.order-date {
  min-width: 75px;
  text-align: center;
}
</style>
